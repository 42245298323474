"use client";

import { createContext } from "react";
import { Notification } from "@koble/graphql";

interface NotificationWebSocketContextProps {
  notifications: Notification[];
  isLoading: boolean;
  fetchData: () => Promise<void>;
}

const NotificationWebSocketContext =
  createContext<NotificationWebSocketContextProps>({
    notifications: [],
    isLoading: true,
    fetchData: async () => {},
  });

export default NotificationWebSocketContext;
