"use client";

import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const useCookie = (keyName: string, defaultValue?: any) => {
  const [cookie, setCookieState] = useState(() => {
    if (defaultValue !== undefined) return defaultValue;
    return Cookies.get(keyName);
  });

  useEffect(() => {
    if (cookie === undefined || cookie === null) {
      // Remove the cookie if the current state is undefined
      Cookies.remove(keyName);
    } else {
      // Set the cookie to the current state value otherwise
      Cookies.set(keyName, cookie);
    }
  }, [cookie, keyName]);

  const setCookie = (newValue: any) => {
    setCookieState(newValue); // Update state with the new value
    // Remove or set the cookie based on the newValue
    if (newValue === undefined) {
      Cookies.remove(keyName);
    } else {
      Cookies.set(keyName, newValue);
    }
  };

  return [cookie, setCookie];
};

export default useCookie;
