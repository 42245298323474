"use client";

import { createContext } from "react";
import { Theme } from "@ant-design/cssinjs";
import { GlobalToken } from "antd";
import { MapToken, SeedToken } from "antd/es/theme/interface";

const ThemeContext = createContext<{
  theme?: {
    theme: Theme<SeedToken, MapToken>;
    token: GlobalToken;
    hashId: string;
  };
}>({});

export default ThemeContext;
