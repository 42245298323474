import { GraphQLClient } from "graphql-request";

export default (uri: string, accessToken?: string) => {
  if (!accessToken) return new GraphQLClient(uri);

  const headers = {
    authorization: `Bearer ${accessToken}`,
  };

  return new GraphQLClient(uri, { headers });
};
