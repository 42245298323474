"use client";

import { createContext, ReactNode, useContext, useMemo } from "react";
import { Modal } from "antd";
import { HookAPI } from "antd/es/modal/useModal";

interface IModalContext {
  modalApi: HookAPI;
  contextHolder: ReactNode;
}

export const ModalContext = createContext<IModalContext>({
  modalApi: {} as HookAPI,
  contextHolder: null,
});

export const useModal = () => useContext(ModalContext);

const ModalChild = ({ children }: { children: ReactNode }) => {
  const { contextHolder } = useModal();

  return (
    <>
      {contextHolder}
      {children}
    </>
  );
};

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modal, contextHolder] = Modal.useModal();

  const value = useMemo(
    () => ({
      modalApi: modal,
      contextHolder,
    }),
    [modal, contextHolder]
  );

  return (
    <ModalContext.Provider value={value}>
      <ModalChild>{children}</ModalChild>
    </ModalContext.Provider>
  );
};
