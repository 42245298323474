"use client";

import { createContext } from "react";
import { Chat, MessageChat, Phone } from "@koble/graphql";
import { IdentityUserType } from "@koble/utils/src/UserSessionManager";

export interface CommonUserProperties {
  type: IdentityUserType;
  id: string;
  firstName: string;
  lastName: string;
  profileImageUrl?: string;
  email?: string;
  phone?: Phone;
}

export interface ExtendedMessageChat extends MessageChat {
  webSocketMessageId?: string;
  status: "sending" | "sent" | "error";
  from: CommonUserProperties;
}

export interface ExtendedChat extends Chat {
  chatMessages: ExtendedMessageChat[];
  allUsers: CommonUserProperties[];
}

interface ChatWebSocketContextProps {
  sendMessage: (chatId: string, message: string, viewerId: string) => void;
  chats: ExtendedChat[];
  isLoading: boolean;
  error: boolean;
  fetchData: () => Promise<void>;
  setChatAsRead: (chatId: string) => void;
  lastWsMessage?: string | MessageEvent;
}

const ChatWebSocketContext = createContext<ChatWebSocketContextProps>({
  sendMessage: () => {},
  chats: [],
  isLoading: true,
  error: false,
  fetchData: async () => {},
  setChatAsRead: () => {},
});

export default ChatWebSocketContext;
