import { Chat } from "@koble/graphql";

import {
  CommonUserProperties,
  ExtendedChat,
  ExtendedMessageChat,
} from "./ChatWebSocketContext";

const convertChatToExtendedChat = (chat: Chat): ExtendedChat => {
  let chatMessages: ExtendedMessageChat[] = [];
  const allUsers: CommonUserProperties[] = [];

  for (const userRecruiterChat of chat.userRecruiterChats) {
    const commonUser: CommonUserProperties = {
      type: "userRecruiter",
      firstName: userRecruiterChat.userRecruiter.firstName,
      lastName: userRecruiterChat.userRecruiter.lastName,
      id: userRecruiterChat.userRecruiter.userRecruiterId,
      profileImageUrl:
        userRecruiterChat.userRecruiter.profileImageUrl || undefined,
      phone: userRecruiterChat.userRecruiter.phone || undefined,
      email: userRecruiterChat.userRecruiter.email || undefined,
    };

    allUsers.push(commonUser);

    for (const chatMessage of userRecruiterChat.chatMessages) {
      chatMessages.push({
        ...chatMessage,
        status: "sent",
        from: commonUser,
      });
    }
  }

  for (const userStudentChat of chat.userStudentChats) {
    const commonUser: CommonUserProperties = {
      type: "userStudent",
      firstName: userStudentChat.userStudent.firstName,
      lastName: userStudentChat.userStudent.lastName,
      id: userStudentChat.userStudent.userStudentId,
      profileImageUrl: userStudentChat.userStudent.profileImageUrl || undefined,
      phone: userStudentChat.userStudent.phone || undefined,
      email: userStudentChat.userStudent.email || undefined,
    };

    allUsers.push(commonUser);

    for (const chatMessage of userStudentChat.chatMessages) {
      chatMessages.push({
        ...chatMessage,
        status: "sent",
        from: commonUser,
      });
    }
  }

  chatMessages = chatMessages.sort((a, b) => {
    return new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime();
  });

  return {
    ...chat,
    chatMessages,
    allUsers,
  };
};

export default convertChatToExtendedChat;
