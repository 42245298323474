"use client";

import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { message, notification } from "antd";
import { MessageInstance } from "antd/lib/message/interface";
import { NotificationInstance } from "antd/lib/notification/interface";

interface IMessageContext {
  messageApi: MessageInstance;
  contextHolder: ReactNode;
  notificationApi: NotificationInstance;
  contextHolderNotification: ReactNode;
}

export const MessageContext = createContext<IMessageContext>({
  messageApi: {} as MessageInstance,
  contextHolder: null,
  notificationApi: {} as NotificationInstance,
  contextHolderNotification: null,
});

export const useMessage = () => useContext(MessageContext);

const MessageChild = ({ children }: { children: ReactNode }) => {
  const { contextHolder, contextHolderNotification } = useMessage();

  return (
    <>
      {contextHolder}
      {contextHolderNotification}
      {children}
    </>
  );
};
export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [notificationApi, contextHolderNotification] =
    notification.useNotification();

  const value = useMemo(
    () => ({
      messageApi,
      contextHolder,
      notificationApi,
      contextHolderNotification,
    }),
    [messageApi, contextHolder, notificationApi, contextHolderNotification]
  );
  return (
    <MessageContext.Provider value={value}>
      <MessageChild>{children}</MessageChild>
    </MessageContext.Provider>
  );
};
