/* eslint-disable no-continue */

"use client";

import { ReactElement, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { UserOutlined } from "@ant-design/icons";
import { GetChatsDocument } from "@koble/graphql";
import {
  isClientError,
  parseAndDisplayGraphqlErrors,
} from "@koble/utils/src/GraphQL";
import { Avatar, Button } from "antd";
import { ClientError } from "graphql-request";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { useAuth } from "../Auth";
import { useGraphQLRequest } from "../GraphQLRequestHook";
import { useMessage } from "../Message";

import ChatWebSocketContext, {
  ExtendedChat,
  ExtendedMessageChat,
} from "./ChatWebSocketContext";
import convertChatToExtendedChat from "./convertChatToExtendedChat";

const ChatWebSocketProvider = ({
  children,
  accessToken,
  wsUrl,
  chatUrlPrefix,
}: {
  children: ReactElement;
  accessToken: string;
  wsUrl: string;
  chatUrlPrefix: string;
}) => {
  const {
    sendMessage: wsSendMessage,
    lastMessage,
    // readyState,
  } = useWebSocket(`${wsUrl}/chat?access_token=${accessToken}`, {
    shouldReconnect: () => true,
  });
  const { messageApi, notificationApi } = useMessage();
  const { identityClaims } = useAuth();

  const [chats, setChats] = useState<ExtendedChat[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const path = usePathname();

  const { authenticatedGraphQLRequestClient } = useGraphQLRequest();

  const setChatAsRead = (chatId: string) => {
    setChats((prevChats) => {
      const newChats = prevChats.map((chat) => ({ ...chat }));
      const chat = newChats.find((c) => c.chatId === chatId);
      if (chat) {
        chat.unreadMessagesCount = 0;
      }
      return newChats;
    });
  };

  const fetchData = async () => {
    if (!authenticatedGraphQLRequestClient)
      throw new Error("Ocurrió un error al obtener los chats");

    try {
      if (firstLoad) {
        setFirstLoad(false);
        setIsLoading(true);
      }

      const ch = await authenticatedGraphQLRequestClient.request(
        GetChatsDocument
      );

      // wait 500 ms to avoid flickering
      await new Promise((resolve) => setTimeout(resolve, 500));

      const newChats = ch.chats?.map((c) =>
        convertChatToExtendedChat(c as any)
      );

      setChats(newChats || []);
    } catch (e) {
      if (isClientError(e)) {
        await parseAndDisplayGraphqlErrors(e as ClientError, messageApi);
      } else {
        messageApi.error("Ocurrió un error al obtener los chats.");
      }
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (path.includes("/chat")) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const processNewMessage = async (data: {
      chatId: string;
      chatMessageId: number;
      dateTime: string;
      fromUserId: string;
      message: string;
      webSocketMessageId: string;
    }) => {
      // await new Promise((resolve) => setTimeout(resolve, 250));

      setChats((prevChats) => {
        const newChats = prevChats.map((chat) => ({ ...chat })); // Create a deep copy of the chats

        let updatedChat = null;

        for (const chat of newChats) {
          if (chat.chatId === data.chatId) {
            const chatMessages = chat.chatMessages.map((chatMessage) => ({
              ...chatMessage,
            }));

            if (data.webSocketMessageId) {
              for (const chatMessage of chatMessages) {
                if (
                  chatMessage.webSocketMessageId === data.webSocketMessageId
                ) {
                  chatMessages.splice(chatMessages.indexOf(chatMessage), 1);
                }
              }
            }

            const from = chats
              .find((c) => c.chatId === data.chatId)
              ?.allUsers.find((user) => user.id === data.fromUserId);

            if (!from) throw new Error("User not found");

            const newMessage: ExtendedMessageChat = {
              message: data.message,
              dateTime: new Date(data.dateTime),
              webSocketMessageId: data.webSocketMessageId,
              status: "sent",
              chatMessageId: data.chatMessageId,
              from,
            };

            chatMessages.push(newMessage);

            chat.chatMessages = chatMessages;

            if (identityClaims?.sub !== data.fromUserId) {
              chat.unreadMessagesCount = chat.unreadMessagesCount
                ? chat.unreadMessagesCount + 1
                : 1;
            }
            updatedChat = chat;
          }
        }

        if (updatedChat) {
          // Remove the updated chat from its original position
          const updatedChatIndex = newChats.findIndex(
            (chat) => chat.chatId === data.chatId
          );
          newChats.splice(updatedChatIndex, 1);
          // Insert the updated chat at the beginning of the array
          newChats.unshift(updatedChat);
        }

        return newChats;
      });

      if (chats.length === 0) {
        await fetchData();
      }
    };

    if (lastMessage !== null) {
      const parsedLastMessage = JSON.parse(lastMessage.data);

      if (parsedLastMessage.messageWebSocketDTO) {
        const data = parsedLastMessage.messageWebSocketDTO;

        if (
          identityClaims?.sub !== data.fromUserId &&
          !path.includes(data.chatId)
        ) {
          notificationApi.success({
            message: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ marginLeft: 10 }}>
                  {data.firstName} {data.lastName}
                </span>
                <Link href={`${chatUrlPrefix}/${data.chatId}`}>
                  <Button size="small" type="link">
                    Ver
                  </Button>
                </Link>
              </div>
            ),
            closeIcon: false,
            placement: "bottomLeft",
            description:
              data.message && data.message.length > 100
                ? `${data.message.substring(0, 100)}...`
                : data.message,
            icon: (
              <Avatar
                style={{
                  backgroundColor: "#F5F5F5",
                  marginRight: 20,
                }}
                src={data.profileImageUrl}
              >
                <UserOutlined style={{ fontSize: 14, color: "#606060" }} />
              </Avatar>
            ),
            style: {
              borderLeft: "3px solid #EB7A15",
            },
          });
        }

        processNewMessage(data).then(() => {});
      } else {
        messageApi.error("Ocurrió un error al enviar el mensaje.");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const sendMessage = (chatId: string, message: string, viewerId: string) => {
    const trimmedMessage = message.trim();
    if (trimmedMessage === "") return;
    const webSocketMessageId = crypto.randomUUID();

    wsSendMessage(
      JSON.stringify({
        ChatId: chatId,
        Message: trimmedMessage,
        WebSocketMessageId: webSocketMessageId,
      })
    );

    const from = chats
      .find((chat) => chat.chatId === chatId)
      ?.allUsers.find((user) => user.id === viewerId);

    if (!from) throw new Error("User not found");

    const newMessage: ExtendedMessageChat = {
      message: trimmedMessage,
      dateTime: new Date(),
      webSocketMessageId,
      status: "sending",
      chatMessageId: 0,
      from,
    };

    setChats((prevChats) => {
      const newChats = prevChats.map((chat) => ({ ...chat })); // Create a deep copy of the chats

      let updatedChat = null;

      for (const chat of newChats) {
        const alreadyExists = chat.chatMessages.find(
          (chatMessage) => chatMessage.webSocketMessageId === webSocketMessageId
        );

        if (alreadyExists) {
          return newChats; // Return early if the message already exists
        }

        if (chat.chatId === chatId) {
          chat.chatMessages.push(newMessage);
          updatedChat = chat;
        }
      }

      if (updatedChat) {
        // Remove the updated chat from its original position
        const updatedChatIndex = newChats.findIndex(
          (chat) => chat.chatId === chatId
        );
        newChats.splice(updatedChatIndex, 1);
        // Insert the updated chat at the beginning of the array
        newChats.unshift(updatedChat);
      }

      return newChats;
    });
  };

  return (
    <ChatWebSocketContext.Provider
      value={{
        sendMessage,
        chats,
        isLoading,
        error,
        fetchData,
        setChatAsRead,
        lastWsMessage: lastMessage || "",
      }}
    >
      {children}
    </ChatWebSocketContext.Provider>
  );
};

const ChatWebSocketProviderWrapper = ({
  accessToken,
  wsUrl,
  children,
  chatUrlPrefix,
}: {
  accessToken?: string;
  wsUrl: string;
  children: ReactElement;
  chatUrlPrefix: string;
}) => {
  if (!accessToken) {
    return <>{children}</>;
  }

  return (
    <ChatWebSocketProvider
      accessToken={accessToken}
      wsUrl={wsUrl}
      chatUrlPrefix={chatUrlPrefix}
    >
      {children}
    </ChatWebSocketProvider>
  );
};

export default ChatWebSocketProviderWrapper;
