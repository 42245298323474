"use client";

import { ReactNode } from "react";
import { GoogleReCaptchaProvider as GoogleReCaptchaProviderGoogle } from "react-google-recaptcha-v3";

const GoogleReCaptchaProvider = ({
  children,
  reCaptchaKey,
}: {
  children: ReactNode;
  reCaptchaKey: string;
}) => {
  return (
    <GoogleReCaptchaProviderGoogle reCaptchaKey={reCaptchaKey}>
      {children}
    </GoogleReCaptchaProviderGoogle>
  );
};

export default GoogleReCaptchaProvider;
