import errors from "@koble/errors";
import { ClientError } from "graphql-request";

import { GraphqlErrorType } from "./GraphqlErrorType";

export default (error: ClientError) => {
  const graphqlError: GraphqlErrorType[] = [];

  error?.response?.errors?.forEach((graphQLError) => {
    const code = graphQLError.extensions?.code;
    const message = graphQLError.message;
    const spanishError = errors[code as string]?.spanish;

    // save code and message, and spanishError if it exists
    graphqlError.push({
      code,
      message,
      spanishError,
    });
  });

  return graphqlError;
};
