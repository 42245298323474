"use client";

import React, { useMemo } from "react";
import { IdentityClaims } from "@koble/utils/src/UserSessionManager";

import AuthContext from "./AuthContext";

const AuthProvider = ({
  children,
  defaultIdentityClaims,
  defaultAccessToken,
  defaultRefreshToken,
}: {
  children: React.ReactNode;
  defaultIdentityClaims?: IdentityClaims;
  defaultAccessToken?: string;
  defaultRefreshToken?: string;
}) => {
  const value = useMemo(
    () => ({
      accessToken: defaultAccessToken,
      refreshToken: defaultRefreshToken,
      identityClaims: defaultIdentityClaims,
    }),
    [defaultAccessToken, defaultRefreshToken, defaultIdentityClaims]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
