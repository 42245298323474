class KobleException extends Error {
  key: string;

  spanish: string;

  constructor(message: string, spanish: string, key: string) {
    super(message);
    this.message = message;
    this.spanish = spanish;
    this.key = key;
  }
}

export default KobleException;
