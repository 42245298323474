"use client";

import { createContext, ReactElement, useContext, useMemo } from "react";
import { AwesomeGraphQLClient } from "awesome-graphql-client";
import { print } from "graphql/language/printer";
import { GraphQLClient } from "graphql-request";

export interface IGraphQLContext {
  graphQLRequestClient: GraphQLClient;
  authenticatedGraphQLRequestClient?: GraphQLClient;
  fileUploadGraphQLRequestClient?: AwesomeGraphQLClient;
}

export const GraphQLRequestContext = createContext<IGraphQLContext>({
  graphQLRequestClient: {} as GraphQLClient,
});

export const GraphQLRequestProvider = ({
  children,
  graphqlUrl,
  accessToken,
}: {
  children: ReactElement;
  graphqlUrl: string;
  accessToken?: string;
}) => {
  const value = useMemo(
    () => ({
      graphQLRequestClient: new GraphQLClient(graphqlUrl),
      authenticatedGraphQLRequestClient: accessToken
        ? new GraphQLClient(graphqlUrl, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          })
        : undefined,
      fileUploadGraphQLRequestClient: accessToken
        ? new AwesomeGraphQLClient({
            endpoint: graphqlUrl,
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
            formatQuery: (query) => print(query as any),
          })
        : undefined,
    }),
    [graphqlUrl, accessToken]
  );

  return (
    <GraphQLRequestContext.Provider value={value}>
      {children}
    </GraphQLRequestContext.Provider>
  );
};

export const useGraphQLRequest = () => useContext(GraphQLRequestContext);
