"use client";

import { createContext } from "react";
import { IdentityClaims } from "@koble/utils/src/UserSessionManager";

interface AuthContextProps {
  accessToken?: string;
  refreshToken?: string;
  identityClaims?: IdentityClaims;
}

const AuthContext = createContext<AuthContextProps>({});

export default AuthContext;
