"use client";

import { createContext, ReactNode, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

export const SessionWebSocketContext = createContext({});

const SessionWebSocketProvider = ({
  children,
  accessToken,
  wsUrl,
  fingerprint,
}: {
  children: ReactNode;
  accessToken?: string;
  wsUrl: string;
  fingerprint: string;
}) => {
  const [isPageVisible, setIsPageVisible] = useState(true);

  const params: Record<string, string> = {
    url: window.location.href,
    fingerprint,
  };

  if (accessToken) {
    params.access_token = accessToken;
  }

  const urlParams = new URLSearchParams(params).toString();

  const { sendJsonMessage, readyState } = useWebSocket(
    `${wsUrl}/sessions?${urlParams}`,
    {
      shouldReconnect: () => true,
    }
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Send a message every 5 seconds to keep the connection alive
  useEffect(() => {
    let interval: any;

    if (readyState !== ReadyState.OPEN || !isPageVisible) {
      return () => clearInterval(interval);
    }

    const sendMessage = () => {
      sendJsonMessage({});
    };

    sendMessage();
    interval = setInterval(() => {
      sendMessage();
    }, 5000); // Sending message every 5 seconds

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(interval);
  }, [sendJsonMessage, readyState, isPageVisible]);

  return (
    <SessionWebSocketContext.Provider value={{}}>
      {children}
    </SessionWebSocketContext.Provider>
  );
};

const SessionWebSocketProviderWrapper = ({
  children,
  accessToken,
  wsUrl,
}: {
  children: ReactNode;
  accessToken?: string;
  wsUrl: string;
}) => {
  const [fingerprint, setFingerprint] = useState("");

  useEffect(() => {
    const fetchFingerprint = async () => {
      const fp = await getCurrentBrowserFingerPrint();
      setFingerprint(fp);
    };

    fetchFingerprint();
  }, []);

  // TODO: This is just a workaround to avoid using the WebSocket in SSR.
  // Next js still executes the code even if the file is marked as client-only.
  // We can´t just compare if window exist because if it does´t an exception is thrown.
  if (typeof window === "undefined" || !fingerprint) {
    return <>{children}</>;
  }

  return (
    <SessionWebSocketProvider
      accessToken={accessToken}
      wsUrl={wsUrl}
      fingerprint={fingerprint}
    >
      {children}
    </SessionWebSocketProvider>
  );
};

export default SessionWebSocketProviderWrapper;
