import { MessageInstance } from "antd/es/message/interface";

import { GraphqlErrorType } from "./GraphqlErrorType";

export default async (
  errors: GraphqlErrorType[],
  messageApi: MessageInstance
) => {
  errors.forEach((error) => {
    if (error.spanishError)
      messageApi.error(
        `Ocurrió un error al realizar la operacion: ${error.spanishError}`
      );
    else
      messageApi.error(
        `Ocurrió un error al realizar la operacion: ${error.message}`
      );
  });
};
