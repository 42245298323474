/* eslint-disable no-continue */

"use client";

import { ReactNode, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { UserOutlined } from "@ant-design/icons";
import { GetNotificationsDocument, Notification } from "@koble/graphql";
import {
  isClientError,
  parseAndDisplayGraphqlErrors,
} from "@koble/utils/src/GraphQL";
import { Avatar, Button } from "antd";
import { ClientError } from "graphql-request";

import { useGraphQLRequest } from "../GraphQLRequestHook";
import { useMessage } from "../Message";

import NotificationWebSocketContext from "./NotificationWebSocketContext";

const NotificationWebSocketProvider = ({
  children,
  accessToken,
  wsUrl,
}: {
  children: ReactNode;
  accessToken: string;
  wsUrl: string;
}) => {
  const {
    // readyState,
    lastMessage,
  } = useWebSocket(`${wsUrl}/notification?access_token=${accessToken}`, {
    shouldReconnect: () => true,
  });
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const { messageApi, notificationApi } = useMessage();
  const { authenticatedGraphQLRequestClient } = useGraphQLRequest();

  useEffect(() => {
    if (!lastMessage) return;

    // get data of the last message of notificationWebSocketDto
    const { notificationWebSocketDto: newNotification } = JSON.parse(
      lastMessage.data
    ) as { notificationWebSocketDto: Notification };

    setNotifications((prev) => {
      // If the notification is already in the list, ignore it
      if (
        prev.find(
          (notification) =>
            notification.notificationId === newNotification.notificationId
        )
      ) {
        return prev;
      }

      return [newNotification, ...prev];
    });

    notificationApi.success({
      message: <span style={{ marginLeft: 10 }}>{newNotification.title}</span>,
      placement: "bottomLeft",
      description:
        newNotification.message && newNotification.message.length > 100
          ? `${newNotification.message.substring(0, 100)}...`
          : newNotification.message,
      icon: (
        <Avatar
          style={{
            backgroundColor: "#F5F5F5",
            marginRight: 20,
          }}
          src={newNotification.imageUrl ? newNotification.imageUrl : undefined}
        >
          <UserOutlined style={{ fontSize: 14, color: "#606060" }} />
        </Avatar>
      ),
      btn: newNotification.redirectUrl && (
        <Button href={newNotification.redirectUrl} size="small">
          {newNotification.actionLabel || "Ver"}
        </Button>
      ),
      style: {
        borderLeft: "3px solid #EB7A15",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const fetchData = async () => {
    if (!authenticatedGraphQLRequestClient)
      throw new Error("Authenticated GraphQL request client not found");

    try {
      if (firstLoad) {
        setFirstLoad(false);
        setIsLoading(true);
      }

      const notificationsQuery =
        await authenticatedGraphQLRequestClient.request(
          GetNotificationsDocument
        );

      // wait 500 ms to avoid flickering
      await new Promise((resolve) => setTimeout(resolve, 500));

      setNotifications(notificationsQuery.notifications as Notification[]);
    } catch (e) {
      if (isClientError(e)) {
        await parseAndDisplayGraphqlErrors(e as ClientError, messageApi);
      } else {
        messageApi.error("Ocurrió un error al obtener las notificaciones");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NotificationWebSocketContext.Provider
      value={{
        notifications,
        isLoading,
        fetchData,
      }}
    >
      {children}
    </NotificationWebSocketContext.Provider>
  );
};

const NotificationWebSocketProviderWrapper = ({
  accessToken,
  wsUrl,
  children,
}: {
  accessToken?: string;
  wsUrl: string;
  children: ReactNode;
}) => {
  if (!accessToken) {
    return <>{children}</>;
  }

  return (
    <NotificationWebSocketProvider accessToken={accessToken} wsUrl={wsUrl}>
      {children}
    </NotificationWebSocketProvider>
  );
};

export default NotificationWebSocketProviderWrapper;
