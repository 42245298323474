"use client";

import React, { ReactNode } from "react";
import { Theme } from "@ant-design/cssinjs";
import { GlobalToken } from "antd";
import { MapToken, SeedToken } from "antd/es/theme/interface";

import ThemeContext from "./ThemeContext";

const ThemeProvider = ({
  children,
  theme,
}: {
  children: ReactNode;
  theme: {
    theme: Theme<SeedToken, MapToken>;
    token: GlobalToken;
    hashId: string;
  };
}) => {
  return (
    <ThemeContext.Provider
      value={{
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
