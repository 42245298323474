import config from "./config";

const getBaseRoute = (isAbsolute?: boolean) => {
  return isAbsolute ? config.NEXT_PUBLIC_SSO_FRONTEND : "";
};
const ssoRouteManager = {
  home(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/`;
  },

  cb(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/cb`;
  },

  complete(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/complete`;
  },

  error(errorId?: string, isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/error${errorId ? `/${errorId}` : ""}`;
  },

  forgotPassword(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/forgot-password`;
  },

  resetPassword(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/reset-password`;
  },

  signIn(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/sign-in`;
  },

  signOut(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/sign-out`;
  },

  signUp(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/sign-up`;
  },

  signUpUserStudent(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/sign-up/user-student`;
  },

  signUpUserRecruiter(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/sign-up/user-recruiter`;
  },
};

export default ssoRouteManager;
